import React, { FC, useCallback, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import useFormValidation from "@/hooks/useFormValidation";

import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { accountResetPassword, accountResetPasswordClear } from "@/actions/account.actions";

import AuthLayout, { defaultInputProps } from "@/components/AuthLayout";
import { Form, Input } from "antd";
import { Rule } from "antd/es/form";

import css from "./style.modules.scss";

const FIELDS_RULES: Record<string, Rule[]> = {
  email: [
    {
      required: true,
      message: "Пожалуйста, введите ваш email"
    },
    {
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, // sky.net-123@example-domain.com
      message: "Некорректный формат email",
    }
  ],
};

interface IFormValues {
  email: string;
}

interface IRestorePasswordFormProps { }

const RestorePasswordForm: FC<IRestorePasswordFormProps> = ({
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm<IFormValues>();
  const values = Form.useWatch([], form);
  const { isDisabled } = useFormValidation(form);

  const { resetPasswordStatus } = useSelector((state: AppStateType) => state.account);

  useEffect(() => {
    return () => {
      dispatch(accountResetPasswordClear());
    };
  }, []);

  useEffect(() => {
    if (resetPasswordStatus === 201) {
      openLoginFrom();
    }
  }, [resetPasswordStatus]);

  const resetPassword = useCallback((): void => {
    dispatch(accountResetPassword(values?.email));
  }, [values]);

  const openLoginFrom = (): void => {
    navigate("/login");
  };

  return (
    <AuthLayout
      title="Восстановление пароля"
      message="Введите адрес электронной почты, который вы используете для входа в аккаунт"
      buttonText="Сбросить пароль"
      onButtonClick={resetPassword}
      buttonDisabled={isDisabled}
      formSize="small"
      actions={(
        <div className="m-0 text-center text-n2">
          <a onClick={openLoginFrom} className={`${css.link} m-0 p-0`}>Назад</a>
        </div>
      )}
    >
      <Form form={form} className="flex flex-col gap-3">
        <Form.Item
          name="email"
          rules={FIELDS_RULES.email}
          className="m-0 w-full"
        >
          <Input {...defaultInputProps} placeholder="E-mail" />
        </Form.Item>
      </Form>
    </AuthLayout>
  );
}

export default RestorePasswordForm;